import React from "react";
import { useLocation } from "react-router-dom";
import Layout from "./components/Layout/Layout";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
function App() {
  const location = useLocation();
  return <Layout path={location.pathname} />;
}

export default App;
