import React, { useState } from 'react';
import "../styles/Teams.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
const emails = [
  {
    "NAME": "Ajith Brand",
    "MAIL": "ajith.brand@example.com",
    "Amount": "100",
    "PLANTYPE": "plan",
    "STATUS": "Received",
    "DATE": "2024-06-01 08:38 AM"
  },
  {
    "NAME": "Kumar",
    "MAIL": "kumar@example.com",
    "Amount": "200",
    "PLANTYPE": "Standard",
    "STATUS": "Received",
    "DATE": "2024-06-01 08:13 AM"
  },
  {
    "NAME": "navenn Brand",
    "MAIL": "navenn.brand@example.com",
    "Amount": "300",
    "PLANTYPE": "Basic",
    "STATUS": "Received",
    "DATE": "2024-06-01 07:52 PM"
  },
  {
    "NAME": "Kumar",
    "MAIL": "kumar@example.com",
    "Amount": "200",
    "PLANTYPE": "Standard",
    "STATUS": "Received",
    "DATE": "2024-06-01 07:52 PM"
  },{
    "NAME": "Kumar",
    "MAIL": "kumar@example.com",
    "Amount": "200",
    "PLANTYPE": "Standard",
    "STATUS": "Received",
    "DATE": "2024-06-01 08:13 AM"
  },
  {
    "NAME": "navenn Brand",
    "MAIL": "navenn.brand@example.com",
    "Amount": "300",
    "PLANTYPE": "Basic",
    "STATUS": "Active",
    "DATE": "2024-06-01 07:52 PM"
  },
  {
    "NAME": "Kumar",
    "MAIL": "kumar@example.com",
    "Amount": "200",
    "PLANTYPE": "Standard",
    "STATUS": "Inactive",
    "DATE": "2024-06-01 07:52 PM"
  },
  {
    "NAME": "Kumar Brand",
    "MAIL": "kumar.brand@example.com",
    "Amount": "400",
    "PLANTYPE": "Premium",
    "STATUS": "Active",
    "DATE": "2024-06-01 04:13 PM"
  },
  {
    "NAME": "Kumar",
    "MAIL": "kumar@example.com",
    "Amount": "200",
    "PLANTYPE": "Standard",
    "STATUS": "Inactive",
    "DATE": "2024-06-01 03:52 PM"
  },
  {
    "NAME": "Kumar",
    "MAIL": "kumar@example.com",
    "Amount": "200",
    "PLANTYPE": "Standard",
    "STATUS": "Inactive",
    "DATE": "2024-06-01 02:30 PM"
  },
  {
    "NAME": "Kumar",
    "MAIL": "kumar@example.com",
    "Amount": "200",
    "PLANTYPE": "Standard",
    "STATUS": "Inactive",
    "DATE": "2024-06-01 08:38 AM"
  },
  {
    "NAME": "Kumar",
    "MAIL": "kumar@example.com",
    "Amount": "200",
    "PLANTYPE": "Standard",
    "STATUS": "Inactive",
    "DATE": "2024-06-01 08:13 AM"
  },
  {
    "NAME": "Kumar",
    "MAIL": "kumar@example.com",
    "Amount": "200",
    "PLANTYPE": "Standard",
    "STATUS": "Inactive",
    "DATE": "2024-06-01 07:52 PM"
  },
  {
    "NAME": "Kumar",
    "MAIL": "kumar@example.com",
    "Amount": "200",
    "PLANTYPE": "Standard",
    "STATUS": "Inactive",
    "DATE": "2024-06-01 04:13 PM"
  },
  {
    "NAME": "Kumar",
    "MAIL": "kumar@example.com",
    "Amount": "200",
    "PLANTYPE": "Standard",
    "STATUS": "Inactive",
    "DATE": "2024-06-01 04:13 PM"
  },
  {
    "NAME": "Kumar",
    "MAIL": "kumar@example.com",
    "Amount": "200",
    "PLANTYPE": "Standard",
    "STATUS": "Inactive",
    "DATE": "2024-06-01 03:52 PM"
  },
  {
    "NAME": "Kumar",
    "MAIL": "kumar@example.com",
    "Amount": "200",
    "PLANTYPE": "Standard",
    "STATUS": "Inactive",
    "DATE": "2024-06-01 02:30 PM"
  },
  {
    "NAME": "Kumar",
    "MAIL": "kumar@example.com",
    "Amount": "200",
    "PLANTYPE": "Standard",
    "STATUS": "Inactive",
    "DATE": "2024-06-01 08:38 AM"
  },
  {
    "NAME": "Kumar",
    "MAIL": "kumar@example.com",
    "Amount": "200",
    "PLANTYPE": "Standard",
    "STATUS": "Inactive",
    "DATE": "2024-06-01 08:13 AM"
  },
  {
    "NAME": "Kumarv",
    "MAIL": "kumarv@example.com",
    "Amount": "300",
    "PLANTYPE": "Basic",
    "STATUS": "Active",
    "DATE": "2024-06-01 07:52 PM"
  }
];

function Paymentdetails() {
  const [currentPage, setCurrentPage] = useState(1);
  const emailsPerPage = 10;

  const indexOfLastEmail = currentPage * emailsPerPage;
  const indexOfFirstEmail = indexOfLastEmail - emailsPerPage;
  const currentEmails = emails.slice(indexOfFirstEmail, indexOfLastEmail);

  const totalPages = Math.ceil(emails.length / emailsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className='Teams'>
      <h6 className='inboxhead'>Inbox</h6>
      <div className='row p-3'>
        <div className='col-lg-2 col-md-3 col-12 detailsswitch'>
         <Link to="/inbox" className='linkdecoration'> <h6 className='paraswitch1'><FontAwesomeIcon icon={faEnvelope} /> Payment details</h6></Link>
         <Link to="/inbox/Report-details" className='linkdecoration'>  <h6 className='paraswitch1 mt-1'><FontAwesomeIcon icon={faStar} /> Report notific
         ation</h6></Link>
        </div>
      </div>
      <div className="email-list  table-responsive1">
        <div className='row'>
          <div className='col-12'>
            <span>Page {currentPage} of {totalPages}</span>
            <span
              className="material-symbols-outlined text-center arrorleft-icon"
              onClick={handlePreviousPage}
            >
              arrow_back_ios
            </span>
            <span
              onClick={handleNextPage}
              className={`material-symbols-outlined arroright-icon ${currentPage === totalPages ? 'disabled' : ''}`}
            >
              arrow_forward_ios
            </span>
          </div>
        </div>
        <table className='table mt-3'>
          <thead className='text-center'>
            <tr className='email-item1'>
              <th>NAME</th>
              <th>MAIL</th>
              <th>AMOUNT</th>
              <th>PLANTYPE</th>
              <th>STATUS</th>
              <th>DATE</th>
            </tr>
          </thead>
          <tbody className='text-center'>
            {currentEmails.map((email, index) => (
              <tr key={index} className="email-item">
                <td >{email.NAME}</td>
                <td>{email.MAIL}</td>
                <td>{email.Amount}</td>
                <td>{email.PLANTYPE}</td>
                <td ><p className='status'>{email.STATUS}</p></td>
                <td>{email.DATE}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Paymentdetails;
