import React from "react";
import Router from "../../routes/Router";
import Sidebar from "../Sidebar/Sidebar";
import './layout.css';

const Layout = ({ path }) => {
  const isLoginPage = path === "/";

  return (
    
      <div className={`main__layout ${isLoginPage }`}>
        {!isLoginPage && <Sidebar />}
        
        <div className="content">
          <Router />
        </div>
      </div>
 
  );
};

export default Layout;
