import React from "react";
import "../styles/plan.css";


const Teams = () => {


  return (
    <div className="plan ">
      <div className="row  planover">
        <h2 className="planhead">Plan schedule</h2>
        <div className="mt-4">
          <h6 className="planhead1">subcribition plan</h6>
          <div className="row subplan">
            <div className="col-3 text-center">plan</div>
            <div className="col-3 text-center">price</div>
            <div className="col-3 text-center">TokenCount</div>
            <div className="col-3 text-center">Action</div>
           
          </div>
          
          <div className="row mt-3  ">
           <form className="subplan1">
            <div className="row">
              <div className="col-3"><input type="text" className="form-control" placeholder="2 months"/></div>
              <div className="col-3"><input type="text" className="form-control" placeholder="2999"/></div>
              <div className="col-3"><input type="text" className="form-control" placeholder="236985"/></div>
              <div className="col-3"><div className="d-flex justify-content-center"> <button className="btn btn-success">edit</button><button className="btn btn-danger saveplanbtn">save</button></div></div>
            

            </div>
           </form>
           
          </div>
          <div className="row mt-1 ">
           <form className="subplan1">
            <div className="row">
              <div className="col-3"><input type="text" className="form-control" placeholder="3 months"/></div>
              <div className="col-3"><input type="text" className="form-control" placeholder="4999"/></div>
              <div className="col-3"><input type="text" className="form-control" placeholder="365987"/></div>
              <div className="col-3"><div className="d-flex justify-content-center"> <button className="btn btn-success">edit</button><button className="btn btn-danger saveplanbtn">save</button></div></div>
            

            </div>
           </form>
           
          </div>
           
          <h6 className="planhead1 mt-4">Add an plan</h6>
        
          <div className="row subplan">
            <div className="col-3 text-center">plan</div>
            <div className="col-3 text-center">price</div>
            <div className="col-3 text-center">TokenCount</div>
            <div className="col-3 text-center">Action</div>
           
          </div>
        
          <div className="row mt-3 ">
           <form className="subplan1">
            <div className="row">
              <div className="col-3"><input type="text" className="form-control" placeholder="1"/></div>
              <div className="col-3"><input type="text" className="form-control" placeholder="1"/></div>
              <div className="col-3"><input type="text" className="form-control" placeholder="1"/></div>
              <div className="col-3"><div className="d-flex justify-content-center"> <button className="btn btn-success">edit</button><button className="btn btn-danger saveplanbtn">save</button></div></div>
            </div>
           </form>
           
          </div>
          
        
     
          
        </div>
      </div>
    </div>
  );
};

export default Teams;
