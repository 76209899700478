import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import "./login.css";

export const Login = ({ setIsLoggedIn }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post("https://api.juristbot.ai/Adminlogin", {
        email,
        pass_word: password, 
      });
      toast.success("Login successful!");
      localStorage.setItem("user", JSON.stringify(response.data));
      setIsLoggedIn(true);

      setTimeout(() => {
        navigate(`/dashboard?id=${response.data.id}`);
      }, 1000);
    } catch (error) {
      if (error.response) {
        toast.error("Login failed. Please check your credentials.");
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="loginbackground">
      <div className="">
        <div className="container-fluid">
          <div className="registration-Loginin p-5">
            <h4 className="signup">Login</h4>
            <p className="loinpar">Please login to admin dashboard</p>
            <form onSubmit={handleLogin}>
              <div>
                <label className="reglable mt-3">Email</label>
                <input
                  type="email"
                  className="reg-form"
                  value={email}
                  placeholder="Enter your email"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="mt-3">
                <label className="reglable">Password</label>
                <input
                  type="password"
                  value={password}
                  className="reg-form"
                  placeholder="Enter your password"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <button
                className="regformbtn2 mt-3"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "Logging in..." : "Login"}
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
