import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Teams from "../pages/Teams";
import Case from "../pages/inbox";
import Dashboardcopy from "../pages/userlist";
import { Login } from "../components/login/login";
import Paymentdetails from "../pages/paymentdata";
import ProtectedRoute from "../privaterout";

const Router = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      setIsLoggedIn(true);
    } else {
      navigate("/");
    }
  }, [navigate]);

  return (
    <Routes>
      <Route path="/" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn}>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/plans"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn}>
            <Teams />
          </ProtectedRoute>
        }
      />
      <Route
        path="/inbox/Report-details"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn}>
            <Case />
          </ProtectedRoute>
        }
      />
      <Route
        path="/inbox"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn}>
            <Paymentdetails />
          </ProtectedRoute>
        }
      />
      <Route
        path="/userlist"
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn}>
            <Dashboardcopy />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default Router;
