import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGauge, faListCheck, faCog, faSignOutAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope, faHeart } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import axios from 'axios';
import "./sidebar.css";

const Sidebar = () => {
  const [showProfileEdit, setShowProfileEdit] = useState(false);
  const [visible, setVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [adminData, setAdminData] = useState({
    username: '',
    email: '',
    mobile_no: '',
    address: '',
    imags: ''
  });

  const toggleProfileEdit = () => {
    setShowProfileEdit(!showProfileEdit);
  };

  useEffect(() => {
    axios.get('https://api.juristbot.ai/Adminsget')
      .then(response => {
        const data = response.data.data[0]; 
        setAdminData({
          username: data.username,
          email: data.email,
          mobile_no: data.mobile_no,
          address: data.address,
          imags: data.imags
        });
      })
      .catch(error => {
        console.error('Error fetching admin data:', error);
      });
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios.put('https://api.juristbot.ai/Adminsupdate', adminData)
      .then(response => {
        setSuccessMessage('Profile updated successfully!');
        setErrorMessage('');
        setLoading(false);
        setTimeout(() => setSuccessMessage(''), 3000);
      })
      .catch(error => {
        console.error('Error updating admin data:', error);
        setErrorMessage('Failed to update profile. Please try again.');
        setLoading(false);
      });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('profileImage', file);
    axios.post('https://api.juristbot.ai/uploadProfileImage', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        setAdminData({ ...adminData, imags: response.data.url });
        setSuccessMessage('Profile image updated successfully!');
        setTimeout(() => setSuccessMessage(''), 3000);
      })
      .catch(error => {
        console.error('Error uploading profile image:', error);
        setErrorMessage('Failed to upload profile image. Please try again.');
      });
  };

  return (
    <>
      <div className="top-nav">
        <img
          src={require("../../assets/logo/align-left.png")}
          alt="logo"
          className="mobile-nav-btn"
          onClick={() => setVisible(!visible)}
        />
        <img
          src={require("../../assets/logo/admin123.png")}
          alt="logo"
          className="top-logo"
        />
        <div className="profile">
          <img src={adminData.imags || "default-image-path.jpg"} alt="Profile" />
          <div className="profile-info">
            <span className="username">{adminData.username}</span>
            <h6 className="textdark">Admin</h6>
          </div>
        </div>
      </div>
      {showProfileEdit && (
        <div className="edit-form-profile">
          <div className="profile-edit">
            <p className="textbtn-end">
              <FontAwesomeIcon
                className="closenav-btn"
                onClick={toggleProfileEdit}
                icon={faTimes}
              />
            </p>
            <h5 className="text-center">Admin profile setting</h5>
            {successMessage && <div className="success-message text-center text-success">{successMessage}</div>}
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            {loading && <div className="loading-message">Updating...</div>}
            <form className="profile-edit-form" onSubmit={handleFormSubmit}>
              <label className="mt-2">Profile Image:</label>
              <input
                className="profile-edit-input mt-2"
                type="file"
                name="profileImage"
                onChange={handleImageUpload}
              />
              <label>Username:</label>
              <input
                className="profile-edit-input mt-1"
                type="text"
                name="username"
                value={adminData.username}
                onChange={(e) => setAdminData({ ...adminData, username: e.target.value })}
              />
              <label className="mt-2">e-mail:</label>
              <input
                className="profile-edit-input mt-1"
                type="email"
                name="email"
                value={adminData.email}
                onChange={(e) => setAdminData({ ...adminData, email: e.target.value })}
              />
              <label className="mt-2">Contact number:</label>
              <input
                className="profile-edit-input mt-1"
                type="tel"
                name="mobile"
                value={adminData.mobile_no}
                onChange={(e) => setAdminData({ ...adminData, mobile_no: e.target.value })}
              />
              <label className="mt-2">Company Address:</label>
              <input
                className="profile-edit-input mt-1"
                type="text"
                name="address"
                value={adminData.address}
                onChange={(e) => setAdminData({ ...adminData, address: e.target.value })}
              />
              <button type="submit" className="invite-buton mt-3">Save profile</button>
            </form>
          </div>
        </div>
      )}
      <div className={visible ? 'navbar open' : 'navbar'}>
        <div className="sidebar__top">
          <div className="sidebar d-flex flex-column justify-content-between bg-white p-4 vh-100">
            <div>
              <ul className="nav link-primary flex-column p-0">
                <li className="nav-item">
                  <Link to="dashboard" className="nav-link navt-sidetext side">
                    <FontAwesomeIcon icon={faGauge} className="me-3 fs-6" />Dashboard
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/plans" className="nav-link navt-sidetext side">
                    <FontAwesomeIcon icon={faHeart} className="me-3 fs-6" />Subscription
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/inbox" className="nav-link navt-sidetext side">
                    <FontAwesomeIcon icon={faEnvelope} className="me-3 fs-6" />Inbox
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/userlist" className="nav-link navt-sidetext side">
                    <FontAwesomeIcon icon={faListCheck} className="me-3 fs-6" />User Lists
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <ul className="nav link-primary flex-column settbottom p-0">
                <li className="nav-item">
                  <Link className="nav-link navt-sidetext side" onClick={toggleProfileEdit}>
                    <FontAwesomeIcon icon={faCog} className="me-3 fs-6" />Settings
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/" className="nav-link navt-sidetext side">
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-3 fs-6" />Logout
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
