import "../styles/dashboard.css";
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndianRupee } from "@fortawesome/free-solid-svg-icons";
import Total from "../assets/logo/Icon.png";
import NeW from "../assets/logo/Icon2.png";
import cicle from "../assets/logo/Icon4.png";
import circle2 from "../assets/logo/icon3.png";
import axios from "axios";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
const data = {
  2022: [
    { name: "Jan", revenue: 192420 },
    { name: "Feb", revenue: 25757 },
    { name: "Mar", revenue: 351028 },
    { name: "Apr", revenue: 902544 },
    { name: "May", revenue: 164421 },
    { name: "Jun", revenue: 401414 },
    { name: "Jul", revenue: 20414 },
    { name: "Aug", revenue: 435741 },
    { name: "Sep", revenue: 80142 },
    { name: "Oct", revenue: 604242 },
    { name: "Nov", revenue: 23424 },
    { name: "Dec", revenue: 10424 },
  ],
};

const Dashboard = () => {
  const [userCount, setUserCount] = useState(0);
  const [newuserCount, setnewuserCount] = useState(0);

  useEffect(() => {
    const fetchUserCount = async () => {
      try {
        const response = await axios.get("https://api.juristbot.ai/users");
        setUserCount(response.data.userCount);
      } catch (err) {
        console.error("Error fetching user count:", err);
      }
    };
     fetchUserCount();
  }, []);

  useEffect(() => {
    const fetchUserCount = async () => {
      try {
        const response = await axios.get("https://api.juristbot.ai/newusers");
        setnewuserCount(response.data.userCount);
      } catch (err) {
        console.error("Error fetching user data:", err);
      }
    };
    fetchUserCount();
  }, []);

  const [selectedYear, setSelectedYear] = useState("2022");

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const getMaxRevenue = () => {
    const allData = [...data["2022"]];
    return Math.max(...allData.map((d) => d.revenue));
  };

  const maxRevenue = getMaxRevenue();
  const percentageTicks = [0.2, 0.4, 0.6, 0.8, 1.0].map(
    (tick) => tick * maxRevenue
  );

  return (
    <div className="dashboard">
      <h5 className="dashstart">Dashboard</h5>
      <div className="row">
        <div className="col-md-6 col-lg-3 col-sm-6 col-12  mt-2  ">
          <div className="card-boc1   wcards">
            <div className="row">
              <div className="col-9  ">
                <h3 className="card-head p-3"> Total User</h3>{" "}
              </div>
              <div className="col-3  ">
                <img src={Total} className="mt-3" alt="" />
              </div>
              <h5 className=" count-dash">{userCount}</h5>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3 col-sm-6 col-12  mt-2  ">
          <div className="card-boc1   wcards">
            <div className="row">
              <div className="col-9  ">
                <h3 className="card-head p-3"> New User</h3>{" "}
              </div>
              <div className="col-3  ">
                <img src={NeW} className="mt-3" alt="" />
              </div>
              <h5 className=" count-dash">{newuserCount}</h5>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-3 col-sm-6 col-12 mt-2">
          <div className="  card-boc1 wcards ">
            <div className="row">
              <div className="col-9 ">
                <h3 className="card-head p-3">Total Revenve</h3>
              </div>
              <div className="col-3  ">
                <img src={cicle} className="mt-3 " alt="" />
              </div>
              <h6 className=" count-dash">
                {" "}
                <FontAwesomeIcon icon={faIndianRupee} />0
              </h6>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3 col-sm-6 col-12 mt-2 ">
          <div className="  card-boc1 wcards ">
            <div className="row">
              <div className="col-9">
                <h3 className="card-head p-3 ">Token Revenue</h3>
              </div>
              <div className="col-3 ">
                <img src={circle2} className="mt-3" alt="" />
              </div>

              <h5 className=" count-dash">
                {" "}
                <FontAwesomeIcon icon={faIndianRupee} />0
              </h5>
            </div>
          </div>
        </div>
        <div></div>
      </div>
      <div className=" graphline mt-4">
        <h5 className="graphead">Revenue Graph</h5>
        <div className="row ">
          <div className="d-flex justify-content-end ">
            <div className="col-lg-1 col-md-2 col-sm-5 col-6">
              <select
                id="year-select"
                class="form-select    "
                value={selectedYear}
                onChange={handleYearChange}
              >
                {Object.keys(data).map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="pt-2">
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              data={data[selectedYear]}
              margin={{ top: 5, right: 10, left: 10, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis
                ticks={percentageTicks}
                tickFormatter={(value) =>
                  `${((value / maxRevenue) * 100).toFixed(0)}%`
                }
              />
              <Tooltip formatter={(value) => value} />
              <Legend />
              <Line
                type="monotone"
                dataKey="revenue"
                stroke="#ffc107"
                background-color="#ffc107"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
