import React, { useState, useEffect } from 'react';
import "../styles/Teams.css";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faSearch, faStar, faTrash } from "@fortawesome/free-solid-svg-icons";

function Case() {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [reports, setReports] = useState([]);
  const [selectedReports, setSelectedReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const reportsPerPage = 10;

  useEffect(() => {
    fetch('https://api.juristbot.ai/user-reports')
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          alert(data.message);
        } else {
          setReports(data.reports);
          setLoading(false);
        }
      })
      .catch(error => {
        console.error('Error fetching user reports:', error);
      });
  }, []);

  const handleDelete = () => {
    selectedReports.forEach(reportId => {
      fetch(`https://api.juristbot.ai/user-reports/${reportId}`, {
        method: 'DELETE',
      })
        .then(response => response.json())
        .then(data => {
          if (data.error) {
            alert(data.message);
          } else {
            setReports(reports.filter(report => report.id !== reportId));
          }
        })
        .catch(error => {
          console.error('Error deleting user report:', error);
        });
    });
    setSelectedReports([]);
  };

  const handleSelectReport = (reportId) => {
    if (selectedReports.includes(reportId)) {
      setSelectedReports(selectedReports.filter(id => id !== reportId));
    } else {
      setSelectedReports([...selectedReports, reportId]);
    }
  };

  const filteredReports = reports.filter(report =>
    report.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
    report.report_details.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastReport = currentPage * reportsPerPage;
  const indexOfFirstReport = indexOfLastReport - reportsPerPage;
  const currentReports = filteredReports.slice(indexOfFirstReport, indexOfLastReport);

  const totalPages = Math.ceil(filteredReports.length / reportsPerPage);
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='Teams'>
      <h6 className='inboxhead'>Inbox</h6>
      <div className='row p-3'>
        <div className='col-lg-2 col-md-3 col-12 detailsswitch'>
          <Link to="/inbox" className='linkdecoration'>
            <h6 className='paraswitch1'><FontAwesomeIcon icon={faEnvelope} /> Payment details</h6>
          </Link>
          <Link to="/inbox/Report-details" className='linkdecoration'>
            <h6 className='paraswitch1 mt-1'><FontAwesomeIcon icon={faStar} /> Report notification</h6>
          </Link>
        </div>
      </div>
      <div className="email-list mt-3">
        <div className='row'>
          <div className='col-lg-9 col-md-8 col-12'>
            <div className="search-bar">
              <FontAwesomeIcon className="search-icon" icon={faSearch} />
              <input
                type="text"
                className='search-bar-input'
                placeholder="Search reports"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <div className='col-lg-1 col-md-1 col-4 text-center'>
            <FontAwesomeIcon icon={faTrash} onClick={handleDelete} />
          </div>
          <div className='col-lg-2 col-md-3 col-8 '>
            <span>Page {currentPage} of {totalPages}</span>
            <span
              className="material-symbols-outlined text-center arrorleft-icon"
              onClick={handlePreviousPage}
            >
              arrow_back_ios
            </span>
            <span
              className="material-symbols-outlined arroright-icon"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              arrow_forward_ios
            </span>
          </div>
        </div>
        <div className="table-responsive mt-4">
          <table className='table mt-3'>
            <tbody>
              {currentReports.map((report, index) => (
                <tr key={index} className="email-item">
                  <td>
                    <input
                      type="checkbox"
                      className="form-check-input checboxt"
                      checked={selectedReports.includes(report.id)}
                      onChange={() => handleSelectReport(report.id)}
                    />
                  </td>
                  <td className="text-center">{report.username}</td>
                  <td>{report.report_details}</td>
                  <td className='test-start'>{report.report_date}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Case;
